export const MEDIA_SVG_ADDRESS = `${process.env.REACT_APP_BACKEND_URL}/media/svg`;
export const MEDIA_ILLUSTRATION_ADDRESS = `${process.env.REACT_APP_BACKEND_URL}/media/illustrations`;


export const addMediaUrl = (url: string) => {
    return `https://milligram.az/api/${url}`
};

// SERVER
export const homeSvg = `https://www.svgrepo.com/show/509999/home-plate.svg`;
export const logoSvg = `https://milligram.az/media/ui/milligram_logo.svg`;
export const updatesSvg = `https://www.svgrepo.com/show/435530/update.svg`;
export const aboutUsSvg = `https://www.svgrepo.com/show/486929/about.svg`;

// SVG REPO
export const sunSvg = 'https://www.svgrepo.com/show/6938/sun.svg';
export const moonSvg = 'https://www.svgrepo.com/show/305280/moon.svg';
export const worldSvg = 'https://www.svgrepo.com/show/409357/world.svg';
export const cancelSvg = 'https://www.svgrepo.com/show/419537/cancel-close-delete.svg';
export const downSvg = 'https://www.svgrepo.com/show/509899/down-chevron.svg';
export const translateSvg = `https://www.svgrepo.com/show/364942/translate-fill.svg`;
export const copySvg = `https://www.svgrepo.com/show/520670/copy-2.svg`;
export const arrowRightSvg = `https://www.svgrepo.com/show/533621/arrow-sm-right.svg`;
export const spellCheckSvg = 'https://www.svgrepo.com/show/94257/import.svg';
export const speechSvg = 'https://www.svgrepo.com/show/517569/live-transcribe.svg';
export const burgerSvg = 'https://www.svgrepo.com/show/506800/burger-menu.svg';
export const closeSvg = 'https://www.svgrepo.com/show/510921/close-lg.svg';